import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Columns from "../components/columns"
import SEO from "../components/seo"
import Header from "../components/header"
import Main from "../components/main"
import Button from "../components/button"
import Text, { HeaderText2, Label, SubLabel } from '../components/text';
import PageWrapper from "../components/pageWrapper"
import Hero from "../components/hero"
import Video from "../components/video"
import rem from "../utils/rem"
import flex from "../styles/flex"
import Products from "../components/products"
import { pageWidth } from "../styles/tokens"
import { breakpoints, smaller } from "../styles/responsive"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Velectronic Custom Electronic Development" />
    <Header siteTitle={data.site.siteMetadata.title}>
      <BackgroundImage
        Tag="section"
        fluid={data.headerImage.childImageSharp.fluid}
        backgroundColor="#040e18"
        css={{ backgroundSize: "cover" }}
      >
        <Hero type="odd" nextId="hero-2" id="a-envelope">
          <PageWrapper
            css={{
              ...flex.horizontal,
              ...flex.centerHorizontal,
              ...flex.spaceAround,
              marginTop: rem(50),
              padding: 0,
              [smaller(breakpoints.lg)]: {
                marginTop: rem(25),
              },
              [smaller(breakpoints.sm)]: {
                ...flex.vertical,
                ...flex.verticalReverse,
              },
            }}
          >
            <Text
              tag="h1"
              size="lg"
              weight="semibold"
              style={{
                color: "#595F6F",
                textAlign: "left",
                margin: `${rem(50)} 0`,
              }}
            >
              Introducing Velectronic <br />
              <span
                css={{
                  fontSize: rem(80),
                  letterSpacing: "1.67px",
                  color: "#1C1B1F",
                  display: "block",
                  [smaller(breakpoints.md)]: {
                    fontSize: rem(60),
                    paddingTop: rem(10),
                  },
                }}
              >
                A-envelope
              </span>
              <br />
              <Button to="/a-envelope" style={{ marginTop: rem(25) }}>
                Explore now
              </Button>
            </Text>
            <div
              css={{
                maxWidth: rem(400),
                width: "100%",
                height: "100%",
                boxShadow: "0 20px 40px 0 rgba(0,0,0,0.27)",
                [smaller(breakpoints.xlg)]: {
                  maxWidth: rem(325),
                },
                [smaller(breakpoints.lg)]: {
                  maxWidth: rem(275),
                },
                [smaller(breakpoints.sm)]: {
                  maxWidth: rem(250),
                },
              }}
            >
              <Img fluid={data.module.childImageSharp.fluid} alt="A-envelope" />
            </div>
          </PageWrapper>
        </Hero>
        <Hero type="even" color="#fff" id="hero-2" nextId="about-us">
          <PageWrapper style={{ ...flex.vertical, textAlign: "center" }}>
            <Text
              tag="h2"
              size="xxlg"
              weight="semibold"
              style={{ color: "#fff" }}
            >
              High quality solutions to your technological needs
            </Text>
          </PageWrapper>
        </Hero>
      </BackgroundImage>
    </Header>
    <Main>
      <section id="about-us">
        <PageWrapper style={{ ...flex.vertical, textAlign: "center" }}>
          <header>
            <HeaderText2>
              The reference company for new products development in electronics
              industry
            </HeaderText2>
          </header>
          <Columns count="2" style={{ padding: `${rem(50)} 0` }}>
            <Text
              style={{
                color: "#4B5E6C",
                textAlign: "left",
              }}
            >
              We are an electronic development company founded in 1972 based on
              creativity and quality, able to offer its customers solutions to
              their technological needs based on their knowledge of electronic,
              computer and mechanical engineering.
            </Text>
            <Text
              style={{
                color: "#4B5E6C",
                textAlign: "left",
              }}
            >
              Our values are lasting interpersonal relationships based on trust
              and mutual respect.
            </Text>
            <Text
              style={{
                color: "#4B5E6C",
                textAlign: "left",
              }}
            >
              A firm commitment to the agreements and agreements with the
              client. A willingness to listen to the ideas and opinions
              proposed, guiding the client in the formulation of their demands
              honestly and, always aligning ourselves with their interests and
              passion and enthusiasm for work well done and future
            </Text>
          </Columns>
        </PageWrapper>
        <Video
          src={"https://www.youtube.com/embed/wm0lEvZDld0?rel=0"}
          css={{
            ...flex.horizontal,
            ...flex.centerHorizontal,
            margin: `${rem(50)} auto ${rem(175)}`,
            maxWidth: pageWidth,
            width: "100%",
            padding: `0 ${rem(50)}`,
            [smaller(breakpoints.sm)]: {
              margin: `${rem(25)} auto ${rem(85)}`,
              padding: 0,
              height: "auto!important",
              minHeight: rem(400),
            },
          }}
          title="Manufacturing process"
        />
      </section>
      <section style={{ backgroundColor: "#F6F8F9" }} id="products">
        <PageWrapper>
          <header style={{ textAlign: "center" }}>
            <Text
              tag="h4"
              size="xlg"
              weight="semibold"
              style={{
                color: "#1C1B1F",
                margin: `${rem(70)} 0 ${rem(20)}`,
              }}
            >
              Our products
            </Text>
            <Text
              style={{
                color: "#4B5E6C",
              }}
            >
              Discover our products in different sectors
            </Text>
          </header>
          <Products />
        </PageWrapper>
      </section>
      <Img
        fluid={data.contentImage.childImageSharp.fluid}
        alt="Electronic components"
      />
    </Main>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    headerImage: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    module: file(relativePath: { eq: "a-envelope.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentImage: file(relativePath: { eq: "product_image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
