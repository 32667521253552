import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Box from './box';
import IndustrialSvg from '../assets/industry.svg';
import ScientificSvg from '../assets/science.svg';
import ClimateSvg from '../assets/climat.svg';
import SoundSvg from '../assets/sound.svg';
import Product from './product';
import flex from '../styles/flex';
import rem from '../utils/rem';
import { breakpoints, smaller } from '../styles/responsive';

const Products = () => {
  const [tabActive, setTabActive] = React.useState("industrial")
  return (
    <StaticQuery
      query={graphql`
        query ProductsQuery {
          industrial1: file(relativePath: { eq: "products/inverter.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          industrial2: file(relativePath: { eq: "products/detector de velocidad de hilo multifilamento.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          industrial3: file(relativePath: { eq: "products/barrera_de_proteccion_modulada_laser_para_prensas_y_plegadoras.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          industrial4: file(relativePath: { eq: "products/variador_de_frecuencia_para_motores_trifasicos_de_hasta_400Hz.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          industrial5: file(relativePath: { eq: "products/generador_de_ultrasonidos_industrial_para_atomizacion.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          industrial6: file(relativePath: { eq: "products/detector_de_nivel_optico.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          scientific1: file(relativePath: { eq: "products/goniometro.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          scientific2: file(relativePath: { eq: "products/centrifuga.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          climate1: file(relativePath: { eq: "products/conmutador_de_8_lineas_RJ45.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          climate2: file(relativePath: { eq: "products/controlador_de_puerta.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          climate3: file(relativePath: { eq: "products/controlador_de_velocidad_para_motores_trifasicos_para_ventilacion.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          climate4: file(relativePath: { eq: "VeraElectroniValles-43.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          climate5: file(relativePath: { eq: "products/sensor_de_flujo_de_aire.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          climate6: file(relativePath: { eq: "products/switch_HA_para_motores_en_ventilacion.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          sound1: file(relativePath: { eq: "products/amplificador_a_valvulas_de_respuesta_plana_topologia_AB-Plus.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          sound2: file(relativePath: { eq: "products/a-envelope.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <div
            css={{
              ...flex.horizontal,
              ...flex.spaceBetween,
              ...flex.wrap,
              margin: `${rem(60)} auto`,
              [smaller(breakpoints.md)]: {
                margin: `${rem(60)} auto`,
              },
            }}
          >
            <Box
              title="Industrial"
              active={tabActive === "industrial"}
              Icon={IndustrialSvg}
              onClick={() => setTabActive("industrial")}
              Tag="button"
            />
            <Box
              title="Scientific"
              active={tabActive === "scientific"}
              Icon={ScientificSvg}
              onClick={() => setTabActive("scientific")}
              Tag="button"
            />
            <Box
              title="Climate control"
              active={tabActive === "climate-control"}
              Icon={ClimateSvg}
              onClick={() => setTabActive("climate-control")}
              Tag="button"
            />
            <Box
              title="Sound"
              active={tabActive === "sound"}
              Icon={SoundSvg}
              onClick={() => setTabActive("sound")}
              Tag="button"
            />
          </div>
          <div
            css={{
              ...flex.horizontal,
              ...flex.spaceBetween,
              ...flex.wrap,
              [smaller(breakpoints.md)]: {
                ...flex.vertical,
                ...flex.centerVertical,
              },
            }}
          >
            <Product
              title="Single Phase Motor Current Controller"
              description={<span></span>}
              image={data.industrial1.childImageSharp.fluid}
              style={{
                ...(tabActive === "industrial" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Optical yarn break sensor"
              description={<span>DHP sensors are based on an optical recognition approach. These sensors are able to check not only the presence of a yarn, but also if the yarn is moving or stopped. Its main application is for winding machines and looms.</span>}
              image={data.industrial2.childImageSharp.fluid}
              style={{
                ...(tabActive === "industrial" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Modulated laser protection barrier for presses and bending machines"
              description={<span></span>}
              image={data.industrial3.childImageSharp.fluid}
              style={{
                ...(tabActive === "industrial" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Variable frequency drive for three-phase motors up to 400Hz"
              description={<span></span>}
              image={data.industrial4.childImageSharp.fluid}
              style={{
                ...(tabActive === "industrial" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Industrial ultrasonic generator for atomization"
              description={<span></span>}
              image={data.industrial5.childImageSharp.fluid}
              style={{
                ...(tabActive === "industrial" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Optical level sensor"
              description={<span></span>}
              image={data.industrial6.childImageSharp.fluid}
              style={{
                ...(tabActive === "industrial" ? {} : { display: "none" }),
              }}
            />

            <Product
              title="2 Axis Gravitational Goniometer"
              description={<span>Allows 2 axis absolute and relative angular measurements, with a capture button on the top of sensor, to allow a rapid and easy exploration. <br />This goniometer is meant to be used to capture biometric information.<br />
                Has also a set of additional tools like belts and plates, and also a laser cross to take measurements without the need of physical contact.</span>}
              image={data.scientific1.childImageSharp.fluid}
              style={{
                ...(tabActive === "scientific" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="High speed programmable centrifuge"
              description={<span>Control module for high speed centrifuge with inertial control feedback to assure a constant angular speed. Allows also time settings.</span>}
              image={data.scientific2.childImageSharp.fluid}
              style={{
                ...(tabActive === "scientific" ? {} : { display: "none" }),
              }}
            />

            <Product
              title="High availability Switch"
              description=""
              image={data.climate1.childImageSharp.fluid}
              style={{
                ...(tabActive === "climate-control" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Air curtain/Door controller"
              description=""
              image={data.climate2.childImageSharp.fluid}
              style={{
                ...(tabActive === "climate-control" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="3 Phase high power air curtain driver"
              description=""
              image={data.climate3.childImageSharp.fluid}
              style={{
                ...(tabActive === "climate-control" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Climate programmer with RC"
              description=""
              image={data.climate4.childImageSharp.fluid}
              style={{
                ...(tabActive === "climate-control" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Air flow sensor"
              description=""
              image={data.climate5.childImageSharp.fluid}
              style={{
                ...(tabActive === "climate-control" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="High Availability Switch for fan motors"
              description=""
              image={data.climate6.childImageSharp.fluid}
              style={{
                ...(tabActive === "climate-control" ? {} : { display: "none" }),
              }}
            />

            <Product
              title="Dual Channel Valve Amplifier"
              description={<span>Two high impedance preamps with independent gain.<br />
               Master output for high power stage. <br />
               Phones out with a specific valve amplifier.<br />
                Two independent send and return channels, with FX control volume.<br />
                High fidelity balanced output for direct insert in high end recording systems.<br />
                12Watt power out with 16/8 Ohms selector.</span>}
              image={data.sound1.childImageSharp.fluid}
              style={{
                ...(tabActive === "sound" ? {} : { display: "none" }),
              }}
            />
            <Product
              title="Quadruple Associative Envelope Generator"
              description={<span></span>}
              image={data.sound2.childImageSharp.fluid}
              style={{
                ...(tabActive === "sound" ? {} : { display: "none" }),
              }}
            />
          </div>
        </div>
      )}
    />
  )
}

export default Products
