import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"

import Text from "./text"
import flex from "../styles/flex"
import rem from "../utils/rem"
import { breakpoints, smaller } from "../styles/responsive"
import "../styles/keyframes.css"

const Product = ({ title, image, description, children, ...rest }) => (
  <div {...rest}>
    <Img
      fluid={image}
      alt={title}
      objectFit="contain"
      objectPosition="left"
      imgStyle={{ objectFit: 'contain', objectPosition: 'left center' }}
    />
    <Text
      weight="semibold"
      style={{
        color: "#4B5E6C",
        textTransform: "uppercase",
        margin: `${rem(40)} 0 ${rem(30)}`,
      }}
    >
      {title}
    </Text>
    <Text weight="semibold" style={{ color: "#1C1B1F" }}>
      {description}
    </Text>
    {children}
  </div>
)

Product.propTypes = {
  title: PropTypes.string.isRequired,
}

export default styled(Product)({
  ...flex.vertical,
  width: "50%",
  padding: `0 ${rem(50)}`,
  margin: `0 ${rem(-50)} ${rem(50)}`,
  opacity: 0,
  animationName: "fadeIn",
  animationIterationCount: 1,
  animationTimingFunction: "ease-in",
  animationFillMode: "forwards",
  animationDuration: "0.75s",
  [smaller(breakpoints.md)]: {
    width: "100%",
    padding: 0,
    margin: `0 0 ${rem(50)}`,
  },
})
