import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

import { breakpoints, smaller } from "../styles/responsive"
import Text from "./text"
import flex from "../styles/flex"
import rem from "../utils/rem"

const Box = ({ title, Icon, Tag, active, ...rest }) => (
  <Tag {...rest}>
    <span
      css={{
        marginBottom: rem(15),
        [smaller(breakpoints.sm)]: {
          marginBottom: rem(5),
        },
      }}
    >
      <Icon
        css={{
          [smaller(breakpoints.sm)]: {
            height: rem(25),
          },
        }}
      />
    </span>
    <Text
      weight="semibold"
      style={{
        color: "#4B5E6C",
        textTransform: "uppercase",
        margin: 0,
        [smaller(breakpoints.md)]: {
          fontSize: rem(14),
        },
        [smaller(breakpoints.sm)]: {
          fontSize: rem(8),
        },
      }}
    >
      {title}
    </Text>
  </Tag>
)
Box.defaultProps = {
  Tag: "div",
}
Box.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  active: PropTypes.bool,
  Tag: PropTypes.string,
}

export default styled(Box)(({ active, onClick }) => ({
  width: rem(200),
  height: rem(200),
  ...flex.vertical,
  ...flex.centerVertical,
  padding: 0,
  border: "1px solid",
  borderColor: active ? "#6D7C88" : "#DEE4E7",
  cursor: onClick ? "pointer" : "default",
  backgroundColor: "transparent",
  [smaller(breakpoints.md)]: {
    width: rem(150),
    height: rem(150),
  },
  [smaller(breakpoints.sm)]: {
    width: rem(80),
    height: rem(80),
  },
}))
