import React from "react"

const Video = ({ src, title, ...rest }) => (
  <iframe
    height="585"
    {...rest}
    src={src}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title={title}
  />
)

export default Video
